import * as React from 'react';
import Andrew from '../assets/Andrew.png';
import '../styles/Profile.css';

function InstagramFeedAndrew() {
  return (
    <div className="bio">
      <div className="bio-container">
        <div className="column1">
          <img src={Andrew}></img>
        </div>
        <div className="column1">
          <h2>Andrew Buscaglia</h2>
            <p>Graduated from UB with a bachelors in Art. He has been tattooing and with 6th Order since 2008, all styles, all custom art.
            </p>
           <p>
            </p>
            <p>
            You can contact me through my instagram at <a href="https://www.instagram.com/6thskinslave">@6thskinslave</a>.
            </p>
</div>
      </div>
      <div className="profile-ig">
          <iframe width="100%" height="1600px" src="https://www.instagram.com/6thskinslave/embed"></iframe>
      </div>
          
      </div>
  );
}

export default InstagramFeedAndrew;

