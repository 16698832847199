import * as React from 'react';
import Josh from '../assets/Josh.png';
import '../styles/Profile.css';

function InstagramFeedJosh() {
  return (
    <div className="bio">
      <div className="bio-container">
        <div className="column1">
          <img src={Josh}></img>
        </div>
        <div className="column1">
          <h2>Josh Rutski</h2>
            <p>Graduated from UB with a bachelors degree in art, and started tattooing in 2012. 
            </p>
            <p>
            Styles- 
            I want to do are flowers, birds, skulls wildlife of various styles (color realism, b&g, neotrad, new school) bio organic, space, weird stuff, mandalas. 
            </p>
            <p>
            To book please text: <a href="sms:1-716-800-9573">716-800-9573</a>             </p>
</div>
      </div>
      <div className="profile-ig">
          <iframe width="100%" height="1600px" src="https://www.instagram.com/jrutskiart/embed"></iframe>
      </div>
          
      </div>
  );
}

export default InstagramFeedJosh;

