import * as React from 'react';
import '../styles/Artists.css';
import mainImage from '../assets/Lancaster-6.jpg';
import artist1 from '../assets/Chris.png';
import artist2 from '../assets/Scott.png';
import artist3 from '../assets/Kaylee.png';
import artist4 from '../assets/Andrew.png';
import artist5 from '../assets/Josh.png';
import artist6 from '../assets/Nick.png';
import artist7 from '../assets/Caleb3.jpg';
import artist8 from '../assets/Andrea.png';

const artists = [
    { id: '1', name: 'Chris Coppola', imageUrl: artist1,  igurl: 'chris'},
    { id: '2', name: 'Scott Howard', imageUrl: artist2, igurl: 'scott' },
    { id: '3', name: 'Kaylee Holdsworth', imageUrl: artist3, igurl: 'kaylee'},
    { id: '4', name: 'Andrew Buscaglia', imageUrl: artist4, igurl: 'andrew' },
    { id: '5', name: 'Josh Rutski', imageUrl: artist5, igurl: 'josh' },
    { id: '6', name: 'Nick Kontor', imageUrl: artist6, igurl: 'nick' },
    { id: '7', name: 'Caleb Sanchez', imageUrl: artist7, igurl: 'caleb' },
    { id: '8', name: 'Andrea Richards', imageUrl: artist8, igurl: 'andrea' },
    // Add more artists here
  ];


function Artists() {
    return ( 
        <div className="artists-bg" style={{ backgroundImage: `url(${mainImage})` }}>
          <ul className='card-container'>
            {artists.map((artist) => (
              <li key={artist.id}>
                 <div className="card">
                {/* Use an <a> tag instead of <Link> */}
                <a href={artist.igurl}>
                  <img
                    src={artist.imageUrl}
                    alt="Avatar"
                    onClick={(e) => {
                      e.preventDefault(); // Prevent the default link behavior
                      window.location.href = artist.igurl;
                    
                    }}
                  />
                   <h4><b>{artist.name}</b></h4>
                   <hr style={{width:`85%`}}></hr>
                </a>
                </div>
              </li>
            ))}
          </ul>
        </div>
      );
    };

export default Artists;
