import * as React from 'react';
import '../styles/Merch.css';

function InstagramFeed() {
  return (
      <div className="merch-bg">
          <iframe width="100%" height="1600px" src="https://www.instagram.com/coppola_art/embed"></iframe>
      </div>
  );
}

export default InstagramFeed;

