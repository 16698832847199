import * as React from 'react';
import '../styles/Locations.css';
import mainImage from '../assets/mainBlur.jpg';
import location1 from '../assets/Cheektowaga.jpg';
import location2 from '../assets/Lancaster.jpg';
//import artist3 from '../assets/Artist4B.jpg';

function Locations() {
    return (
        <div className="artists-bg" style={{ backgroundImage: `url(${mainImage})` }}>
            <ul className='location-card-container'>
                <li>
                    <div className="location-card">
                        <img src={location1} alt="Avatar"/>
                        <h4><b>Cheektowaga</b></h4>
                        <hr style={{width:`85%`}}></hr>
                        <p>Lorem ipsum dolor sit amet,
                            consectetur adipiscing elit. Nulla ornare mi nibh,
                            quis molestie lectus tempus id. Aliquam quis commodo
                            enim. Pellentesque habitant morbi tristique senectus
                            et netus et malesuada fames ac turpis egestas. Nulla
                            quis elit felis.</p>
                    </div>
                </li>
                <li>
                    <div className="location-card">
                        <img src={location2} alt="Avatar"/>
                        <h4><b>Lancaster</b></h4>
                        <hr style={{width:`85%`}}></hr>
                        <p>Lorem ipsum dolor sit amet,
                            consectetur adipiscing elit. Nulla ornare mi nibh,
                            quis molestie lectus tempus id. Aliquam quis commodo
                            enim. Pellentesque habitant morbi tristique senectus
                            et netus et malesuada fames ac turpis egestas. Nulla
                            quis elit felis.</p>
                    </div>
                </li>
                {/* <li>
                    <div className="card">
                        <img src={artist3} alt="Avatar"/>
                        <h4><b>Clark Wiles</b></h4>
                        <hr style={{width:`85%`}}></hr>
                        <p>Lorem ipsum dolor sit amet,
                            consectetur adipiscing elit. Nulla ornare mi nibh,
                            quis molestie lectus tempus id. Aliquam quis commodo
                            enim. Pellentesque habitant morbi tristique senectus
                            et netus et malesuada fames ac turpis egestas. Nulla
                            quis elit felis.</p>
                    </div>
                </li> */}
            </ul>
        </div>
    );
}

export default Locations;
