import * as React from 'react';
import '../styles/Merch.css';
import mainImage from '../assets/mainBlur.jpg';
import location1 from '../assets/Cheektowaga.jpg';
import location2 from '../assets/Lancaster.jpg';
//import artist3 from '../assets/Artist4B.jpg';

function Merch() {
    return (
        <div className="merch-bg">
            <iframe width="100%" height="1600px" src="https://www.instagram.com/coppola_art/embed-studio.creator-spring.com/?"></iframe>
        </div>
    );
}

export default Merch;
