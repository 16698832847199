import * as React from 'react';
import Nick from '../assets/Nick.png';
import '../styles/Profile.css';

function InstagramFeedNick() {
  return (
    <div className="bio">
      <div className="bio-container">
        <div className="column1">
          <img src={Nick}></img>
        </div>
        <div className="column1">
          <h2>Nick Konter</h2>
            <p>
            Tattooing and with 6th Order since 2016.            </p>
           <p>
              Styles- Traditional, black and gray work, custom art
            </p>
            <p>
            You can contact me through my instagram at <a href="https://www.instagram.com/nickkonter">@nickkonter</a>.
            </p>
</div>
      </div>
      <div className="profile-ig">
          <iframe width="100%" height="1600px" src="https://www.instagram.com/nickkonter/embed"></iframe>
      </div>
          
      </div>
  );
}

export default InstagramFeedNick;

