import mainImage from '../assets/Lancaster-6.jpg';
import logoImage from '../assets/6thOrderLogo.png';
// import OptimusPrinceps from '../assets/OptimusPrinceps.ttf';

import '../styles/Home.css';


function Home() {
    return (
        <div className="home" style={{ backgroundImage: `url(${mainImage})` }}>
        {/* <div className="home" style={{ backgroundColor: 'black' }}> */}
            <div className="logoContainer">
                <img src={logoImage} alt='logo'/>
             </div>      
        </div>
    );
}

export default Home;


