import * as React from 'react';
import Andrea from '../assets/Andrea.png';
import '../styles/Profile.css';

function InstagramFeedAndrea() {
  return (
    <div className="bio">
      <div className="bio-container">
        <div className="column1">
          <img src={Andrea}></img>
        </div>
        <div className="column1">
          <h2>Andrea Richards</h2>
            <p>Co-owner, shop manager. 
Focuses on maintaining 6th Order Tattoo Studio as a sought-after destination for those seeking exceptional custom artistry and professionalism, along with a fun, friendly atmosphere for a one of a kind overall experience beyond expectations.
            </p>
</div>
      </div>
      {/* <div className="profile-ig">
          <iframe width="100%" height="autopx" src="https://www.instagram.com/6thskinslave/embed"></iframe>
      </div> */}
          
      </div>
  );
}

export default InstagramFeedAndrea;

