import * as React from 'react';
import Kaylee from '../assets/Kaylee.png';
import '../styles/Profile.css';

function InstagramFeedKaylee() {
  return (
    <div className="bio">
      <div className="bio-container">
        <div className="column1">
          <img src={Kaylee}></img>
        </div>
        <div className="column1">
          <h2>Kaylee Holdsworth</h2>
            <p>I have been tattooing since 2017, and joined 6th Order Tattoo Studio in July of 2022.
            </p>
            <p>
            Styles- 
              I love to create and tattoo neo-traditional style, lady faces, and most darker concepts. I am interested in taking on some darker style pieces, animal portraits, black and grey realism, and biomech. I am looking for clients to typically bring me a general idea and allow me artistic freedom with that idea. 

            </p>
          <p>
              If you’re interested  in booking an appointment or a consult with me, I can be reached at:
              <br></br>Email: <a href="mailto:KayleeTattoos@yahoo.com">KayleeTattoos@yahoo.com</a> Or
              <br></br>Instagram message at: <a href="https://www.instagram.com/kaydog3214">@Kaydog3214</a>
            </p>
</div>
      </div>
      <div className="profile-ig">
          <iframe width="100%" height="1600px" src="https://www.instagram.com/kaydog3214/embed"></iframe>
      </div>
          
      </div>
  );
}

export default InstagramFeedKaylee;

