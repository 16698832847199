import './App.css';
import "./fonts/OptimusPrinceps.ttf";
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Locations from './pages/Locations';
import Artists from './pages/Artists';
import Gallery from './pages/Gallery';
import Contact from './pages/Contact';
import Merch from './pages/Merch';
import Events from './pages/Events';
import ArtistProfile from './components/ArtistProfile';
import InstagramFeed from './components/InstagramFeed';
import InstagramFeedChris from './components/InstagramFeedChris';
import InstagramFeedScott from './components/InstagramFeedScott';
import InstagramFeedKaylee from './components/InstagramFeedKaylee';
import InstagramFeedAndrew from './components/InstagramFeedAndrew';
import InstagramFeedJosh from './components/InstagramFeedJosh';
import InstagramFeedNick from './components/InstagramFeedNick';
import InstagramFeedCaleb from './components/InstagramFeedCaleb';
import InstagramFeedAndrea from './components/InstagramFeedAndrea';
import Footer from './components/Footer';
import {Helmet} from "react-helmet";
import {
  BrowserRouter as Router, Route, Routes
} from "react-router-dom";

function App() {
  return (
    <div className="App">

      <Helmet>
        <title>6th Order Tattoo</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" exact element={<Home />} />
          {/* <Route path="/locations" exact element={<Locations />} /> */}
          <Route path="/artists" exact element={<Artists />} />
          <Route path="/gallery" exact element={<Gallery />} />
          <Route path="/contact" exact element={<Contact />} />
          <Route path="/merch" exact element={<Merch />} />
          <Route path="/events" exact element={<Events />} />
          <Route path="/chris" exact element={<InstagramFeedChris />} />
          <Route path="/scott" exact element={<InstagramFeedScott />} />
          <Route path="/kaylee" exact element={<InstagramFeedKaylee />} />
          <Route path="/andrew" exact element={<InstagramFeedAndrew />} />
          <Route path="/josh" exact element={<InstagramFeedJosh />} />
          <Route path="/nick" exact element={<InstagramFeedNick />} />
          <Route path="/caleb" exact element={<InstagramFeedCaleb />} />
          <Route path="/andrea" exact element={<InstagramFeedAndrea />} />
          <Route path="/instagram" exact element={InstagramFeed} />
        </Routes>
        <Footer />
      </Router> 
    </div>
  );
}

export default App;
