import React from 'react';
import "../styles/Footer.css";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
// import TwitterIcon from '@mui/icons-material/Twitter';


function Footer() {
    return (
        <div className="footer">
            <div className="socialMedia">
                <InstagramIcon onClick={() => window.open("https://www.instagram.com/6th_order_tattoo_studio/")}></InstagramIcon><FacebookIcon onClick={() => window.open("https://www.facebook.com/6thorder")}></FacebookIcon>
            </div>
            <div> 
                <p>85 Central Ave. Lancaster, NY 14086
                <a href="tel:716-901-0166"> 716-901-0166</a> 
                 </p>
            </div>
            <p>{(new Date().getFullYear())} - 6th Order Tattoo Studio</p>
        </div>
    );
}

export default Footer;