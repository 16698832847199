import * as React from 'react';
import '../styles/Events.css';
import event from '../assets/event.png';


function Events() {
    return (
        <div>
           <img src={event} class="center"></img>
        </div>
    );
}

export default Events;
