import * as React from 'react';
import Scott from '../assets/Scott.png';
import '../styles/Profile.css';

function InstagramFeedScott() {
  return (
    <div className="bio">
      <div className="bio-container">
        <div className="column1">
          <img src={Scott}></img>
        </div>
        <div className="column1">
          <h2>Scott Howard</h2>
            <p>I’ve been drawing since I was able to walk. It was the only thing growing up that I was always completely in my comfort zone and distracted me from most things. I decided I wanted to pursue tattooing around my sophomore year of high school so right after graduating (2011) is when I went shop to shop searching for an apprenticeship. I was lucky enough to get under the guy doing my tattoos at the time and so I started tattooing in January 2012.
            </p>
            <p>
            Styles- 
Growing up whenever I’d draw, it always came out illustrative and so I gravitate towards the new school style and would love to do more. However when I started tattooing my main focus was photo realism and portraits. I am a giant nerd so rather than style I really just love doing anything of geek culture, movies and whatnot. 

            </p>
            <p>
            You can contact me through my booking site at <a href="https://scotthowardtattoos.square.site">scotthowardtattoos.square.site</a>. Instagram chat is possible but the way it’s set up I can’t assure that I’ll always see it. Sorry in advance
            </p>
</div>
      </div>
      <div>
          <iframe className="iframe" width="100%" height={1600} src="https://www.instagram.com/scottxhoward/embed"></iframe>
      </div>
          
      </div>
  );
}

export default InstagramFeedScott;

