import * as React from 'react';
import Chris from '../assets/Chris.png';
import '../styles/Profile.css';

function InstagramFeedChris() {
  return (
    <div className="bio">
      <div className="bio-container">
        <div className="column1">
          <img src={Chris}></img>
        </div>
        <div className="column1">
          <h2>Chris Coppola</h2>
            <p>Chris grew up in Clarence, NY.  He began tattooing in the year 2000 while attending University at Buffalo.  In 2002, he moved to Oahu, Hawaii working in a popular studio.  Working in Hawaii, he was exposed to many tattoo styles.  Hawaii having a heavy military presence along with a dense Japanese population and local Polynesian culture was a perfect place to develop and hone his tattooing skills while focusing on large scale projects.  It was an immersion in some of the major tattoo styles still popular today.  Upon returning to Western New York, 6th Order Tattoo Studio was founded in 2006 as a tattoo destination specializing in the custom, large scale work he had been developing while working in Hawaii.  Since returning home to Buffalo, he has developed his artistic skills while exploring various mediums such as pen and ink, gouache, and notably oil painting which are prominently displayed in both 6th Order Tattoo locations.  He has also had the privilege to make travel stops alongside the Cirque Du Soleil show “KOOZA” while tattooing both cast and crew.  He has also painted a custom drum kit for the popular band “Fallout Boy.”  Today, Chris spends most of his time continuing to develop his art while also spending his time as a family man to his 2 children and fiancé Andrea.  
            </p>
            <p>
                    Preferred styles:  Currently Chris is working only on select pieces.  He is generally booking about a year out.  He enjoys working on large works in both “Realism” and Japanese styles.  He asks that his clients work with him as they work together to develop their concepts into a tattoo.  Don’t hesitate to reach out for small, palm sized tattoos in a simpler style like American Traditional.  He can sometimes sneak those pieces in on a light day.  

                    Current Hourly Rate: $175/hr

            </p>
            <p>
            You can contact me through text at: <a href="sms:1-808-282-2400">808-282-2400</a>
            </p>
</div>
      </div>
      <div className="profile-ig">
          <iframe width="100%" height="1600px" src="https://www.instagram.com/coppola_art/embed"></iframe>
      </div>
          
      </div>
  );
}

export default InstagramFeedChris;

