import * as React from 'react';
import Caleb from '../assets/Caleb3.jpg';
import '../styles/Profile.css';

function InstagramFeedCaleb() {
  return (
    <div className="bio">
      <div className="bio-container">
        <div className="column1">
          <img src={Caleb}></img>
        </div>
        <div className="column1">
          <h2>Caleb Sanchez</h2>
            <p>
            Caleb Sanchez was born and raised in Buffalo, NY and has been an artist since an early age. He attended the Buffalo Academy for Visual and Performing Arts for all 8 years of middle school and high school. While he attended, he studied in fine arts, dark room photography, print making, illustration, photoshop, sculpting and ceramics.             </p>
           <p>
           Caleb would like to specialize in neo-traditional, realism, and script. Though, he does consider himself a versatile artist. 
            </p>
            <p>
            You can contact me through my instagram at <a href="https://www.instagram.com/calebsanchez_tattoo">@calebsanchez_tattoo</a>.
              <br></br>Cell phone: <a href="tel:716-248-0698"> 716-248-0698</a>
              <br></br>Facebook: Caleb Sanchez 
              <br></br>Email:<a href="mailto:calebsanchez.tattoo@gmail.com"> calebsanchez.tattoo@gmail.com</a>
              <br></br>(Instagram preferred)

            </p>
        </div>
      </div>
      <div className="profile-ig">
          <iframe width="100%" height="1600px" src="https://www.instagram.com/calebsanchez_tattoo/embed"></iframe>
      </div>
          
      </div>
  );
}

export default InstagramFeedCaleb;

